import React from 'react'

import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// MUI
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'

// Local Components
import Layout from '../components/Layout'
import { useStyles } from '../styles/global'

const About = ({ data }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { allMarkdownRemark } = data
  const { frontmatter } = allMarkdownRemark?.edges[0].node
  console.log(data)

  return (
    <div>
      <Layout>
        <Grid container justify="space-around">
          <Box
            display="grid"
            p={['1rem']}
            width="100%"
            height="100%"
          >
            <GatsbyImage
              image={getImage(frontmatter.aboutBackground)}
              alt="hero about us"
              style={{
                width: '100%',
                gridArea: '1/1/4/1',
                display: 'grid',
              }}
              loading="eager"
              objectFit="cover"
            />
            <Box
              style={{
                gridArea: '1/1/2/1',
                placeItems: 'center',
                alignSelf: 'center',
                justifySelf: 'center',
              }}
              position="relative"
              display="grid"
              bgcolor={theme.palette.opaque.paper}
              p={['1rem']}
              mt={['2rem', '3rem', '5rem']}
              mb={['2rem', '3rem', '5rem']}
            >
              <Typography
                variant="h4"
                className={classes.justify}
              >
                {frontmatter.mission}
              </Typography>
            </Box>
            <Box
              style={{
                gridArea: '2/1',
              }}
              justifyContent="center"
              position="relative"
              display="grid"
              bgcolor={theme.palette.opaque.paper}
              p={['1rem']}
              width="100%"
              mb={['2rem', '3rem', '5rem']}
            >
              <Grid
                container
                spacing={1}
                direction="column"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    bgcolor={theme.palette.primary.dark}
                    justifyContent="center"
                    width="100%"
                  >
                    <Typography variant="h3">
                      American Roofing Sales, Inc
                    </Typography>
                  </Box>
                  </Grid>
                <Grid item container xs={12} alignItems="flex-start">
                  <Box
                    display="flex"
                    width="100%"
                    pl={['1rem']}
                  >
                    <Typography
                      variant="h5"
                    >
                      {frontmatter?.companyContact?.phone}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item container xs={12} alignItems="flex-start">
                  <Box
                    display="flex"
                    width="100%"
                    pl={['1rem']}
                  >
                    <Typography
                      variant="h5"
                    >
                      {frontmatter?.companyContact?.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item container xs={12} alignItems="flex-start">
                  <Box
                    display="flex"
                    width="100%"
                    pl={['1rem']}
                  >
                    <Typography
                      variant="h5"
                    >
                      {frontmatter?.companyContact?.address1}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item container xs={12} alignItems="flex-start">
                  <Box
                    display="flex"
                    width="100%"
                    pl={['1rem']}
                  >
                    <Typography
                      variant="h5"
                    >
                      {frontmatter?.companyContact?.address2}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item container xs={12} alignItems="flex-start">
                  <Box
                    display="flex"
                    width="100%"
                    pl={['1rem']}
                  >
                    <Typography
                      variant="h5"
                    >
                      {frontmatter?.companyContact?.address3}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                gridArea: '3/1',
              }}
              position="relative"
              display="grid"
              bgcolor={theme.palette.opaque.paper}
              p={['1rem']}
              width="100%"
              mb={['2rem', '3rem', '5rem']}
            >
              <Grid
                container
                justify="space-evenly"
                spacing={5}
              >
                {frontmatter?.contacts?.map(
                  ({ contact }, index) => (
                    <Grid item xs={12} lg={5} key={index}>
                      <Box
                        display="flex"
                        bgcolor={theme.palette.primary.dark}
                        width="100%"
                        p={['1rem']}
                      >
                        <Typography variant="h3">
                          {contact.name}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        width="100%"
                        pl={['1rem']}
                      >
                        <Typography
                          variant="h5"
                          className={classes.light}
                        >
                          {contact.email}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        width="100%"
                        pl={['1rem']}
                      >
                        <Typography
                          variant="h5"
                          className={classes.light}
                        >
                          {contact.phone}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        width="100%"
                        pl={['1rem']}
                      >
                        <Typography
                          variant="h5"
                          className={classes.light}
                        >
                          {contact.address1}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        width="100%"
                        pl={['1rem']}
                      >
                        <Typography
                          variant="h5"
                          className={classes.light}
                        >
                          {contact.address2}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        width="100%"
                        pl={['1rem']}
                      >
                        <Typography
                          variant="h5"
                          className={classes.light}
                        >
                          {contact.address3}
                        </Typography>
                      </Box>
                    </Grid>
                  ),
                )}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "about" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            companyContact {
              address1
              address2
              address3
              email
              phone
            }
            contacts {
              contact {
                address1
                address2
                address3
                email
                name
                phone
              }
            }
            contactText
            contactLabel
            mission
            aboutBackground {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  aspectRatio: 1.77
                  formats: [AUTO, WEBP, AVIF]
                  quality: 80
                )
              }
            }
          }
        }
      }
    }
  }
`

export default About
